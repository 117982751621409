import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { ProductRoutePath } from '~anyx/common/routing';
import { ProductDetail, ReportSupportedCurrency } from '~anyx/shared/graphql';
import { SkeletonLoader, Image, Card, NumberWithCurrency } from '~anyx/shared/ui';

import { RatingStar } from '../product-review';

interface ProductOverviewCardProps {
  channelSelector?: React.ReactNode;
  product?: Pick<ProductDetail, 'id' | 'name' | 'media' | 'description'>;
  averageRating?: number;
  totalRatings?: number;
  productLoading?: boolean;
  className?: string;
  category?: readonly string[] | null;
  currency?: ReportSupportedCurrency | null;
  price?: string | null;
}

export const ProductOverviewCard = ({
  channelSelector,
  product,
  averageRating,
  totalRatings,
  productLoading,
  category,
  currency,
  price,
  className,
}: ProductOverviewCardProps) => {
  const { t } = useTranslation('shared');

  return (
    <Card withPadding={false} className={classNames('py-2 pl-2 pr-4', className)}>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 flex items-center md:col-span-3">
          <SkeletonLoader loading={productLoading} className="mr-2 h-14 w-14">
            <Image
              alt={t('shared.action.image', {
                ns: 'shared',
                entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
              })}
              className="mr-2 h-14 w-14"
              noImageClassNameWrapper="mr-2 h-14 w-14"
              src={product?.media[0] || ''}
            />
          </SkeletonLoader>
          <div className="flex min-w-0 flex-col">
            <SkeletonLoader loading={productLoading} className="mb-2 h-3.5">
              <Link
                title={product?.name || ''}
                to={{
                  pathname: ProductRoutePath().edit({
                    productId: product?.id || '',
                  }).path,
                }}
                className="group-hover:text-link hover:text-link mb-2 truncate text-sm font-semibold leading-7 hover:underline"
              >
                {product?.name}
              </Link>
            </SkeletonLoader>
            <div className="mb-1 flex gap-2">
              <SkeletonLoader loading={productLoading} className="h-3.5 w-40">
                <div className="leading-5">
                  <NumberWithCurrency amount={price} currency={currency} />
                </div>
              </SkeletonLoader>
              <div className="bg-gray-2 w-px" />
              <SkeletonLoader loading={productLoading} className="h-3.5 w-40">
                <div className="flex items-center gap-2">
                  <RatingStar averageRating={averageRating} size="sm" />
                  <p className="text-xs leading-5">
                    {`${t('report.page.product.outOfFive', {
                      ns: 'report',
                      amount: averageRating,
                      compact: true,
                    })} (${totalRatings})`}
                  </p>
                </div>
              </SkeletonLoader>
            </div>
            <SkeletonLoader loading={productLoading}>
              <p className="text-xs leading-5">{category?.join(' > ')}</p>
            </SkeletonLoader>
          </div>
        </div>

        {channelSelector && (
          <div className="col-span-4 flex items-center gap-2 md:col-span-1 md:justify-end">
            {t('report.page.product.for', { ns: 'report' })}
            {channelSelector}
          </div>
        )}
      </div>
    </Card>
  );
};
