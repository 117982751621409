import { ReactNode, useCallback } from 'react';

import classNames from 'classnames';

import { SortDownIcon, SortUpIcon } from '~anyx/shared/icons';

export type SortButtonProps = {
  label: ReactNode;
  isActive: boolean;
  onSort: (direction: SortingState) => void;
  descending?: boolean | null;
  className?: string;
  align?: 'left' | 'right';
};

export enum SortingState {
  ASC = 'ASC',
  DESC = 'DESC',
  OFF = 'OFF',
}

export const SortButton = ({
  align = 'right',
  onSort,
  isActive,
  descending,
  label,
  className,
}: SortButtonProps) => {
  const direction = descending ? SortingState.DESC : SortingState.ASC;
  const sort = !isActive ? SortingState.OFF : direction;
  const handleSort = useCallback(
    (newDirection: SortingState) => {
      onSort(newDirection);
    },
    [onSort]
  );

  return (
    <div
      className={classNames(
        'group flex w-full cursor-default items-center',
        {
          'justify-between': align === 'left',
          'justify-end': align === 'right',
        },
        className
      )}
    >
      {label}
      <span
        className={classNames('ml-2', {
          'group-hover:opacity-100 lg:opacity-0': !isActive,
        })}
      >
        <SortUpIcon
          onClick={() => handleSort(SortingState.ASC)}
          className={classNames('h-4 w-4 cursor-pointer hover:brightness-50', {
            'text-gray-5': sort === SortingState.DESC || sort === SortingState.OFF,
            'text-gray-9': sort === SortingState.ASC,
          })}
        />
        <SortDownIcon
          onClick={() => handleSort(SortingState.DESC)}
          className={classNames('h-4 w-4 cursor-pointer hover:brightness-50', {
            'text-gray-9': sort === SortingState.DESC,
            'text-gray-5': sort === SortingState.ASC || sort === SortingState.OFF,
          })}
        />
      </span>
    </div>
  );
};
