import { useTranslation } from 'react-i18next';

import { Badge } from '@any-ui-react/core';
import * as Avatar from '@radix-ui/react-avatar';
import classNames from 'classnames';

import { ChatType } from '~anyx/shared/graphql';
import {
  CheckIcon,
  CrossIcon,
  MessengerFaIcon,
  InstagramFaIcon,
  LineFaIcon,
  LazadaFaIcon,
  WhatsappFaIcon,
  ShopeeFaIcon,
  TikTokFaIcon,
} from '~anyx/shared/icons';

interface CrmConnectedDetailProps {
  /** Connection status */
  connected: boolean;
  /** Connected store name */
  name?: string;
  /** Chat type */
  chatType: ChatType;
  /** Additional data to display */
  additionalInfo?: React.ReactNode;
  /** Connected store profilePic */
  profilePic?: string;
  /** ClassName */
  className?: string;
  /** Text className */
  textClassName?: string;
}

const CHAT_ICONS = {
  WHATSAPP: <WhatsappFaIcon className="h-16 w-16" />,
  MESSENGER: <MessengerFaIcon className="h-16 w-16" />,
  INSTAGRAM: <InstagramFaIcon className="h-16 w-16" />,
  LINE: <LineFaIcon className="h-16 w-16" />,
  LAZADA: <LazadaFaIcon className="h-16 w-16" />,
  SHOPEE: <ShopeeFaIcon className="h-16 w-16" />,
  TIKTOK_SHOP: <TikTokFaIcon className="h-16 w-16" />,
};

export const CrmConnectedDetails = ({
  connected,
  name,
  chatType,
  additionalInfo,
  profilePic,
  className,
  textClassName,
}: CrmConnectedDetailProps) => {
  const { t } = useTranslation('shared');
  return (
    <div className={className}>
      <div className="text-gray-9 mb-8 flex items-center gap-x-4 lg:mb-5">
        <span className="text-sm">{t('shared.field.status.name')}</span>
        <Badge
          leftSection={
            connected ? (
              <CheckIcon className="text-success h-3 w-3" />
            ) : (
              <CrossIcon className="text-failure h-3 w-3" />
            )
          }
          color={connected ? 'green' : 'red'}
        >
          <span className="text-2xs">
            {connected ? t('shared.button.connected') : t('shared.button.disconnected')}
          </span>
        </Badge>
      </div>
      <div className="border-gray-2 bg-gray-0 flex w-full items-center gap-x-4 border p-3">
        <Avatar.Root>
          <Avatar.Image
            className="h-16 w-16 overflow-hidden rounded-full object-cover"
            src={profilePic}
            alt={name}
          />
          <Avatar.Fallback>{CHAT_ICONS[chatType]}</Avatar.Fallback>
        </Avatar.Root>

        <div className="flex flex-col">
          {name && (
            <span
              className={classNames(
                'text-gray-9 text-base',
                { 'lg:text-lg': !additionalInfo },
                textClassName
              )}
            >
              {name}
            </span>
          )}
          {additionalInfo}
        </div>
      </div>
    </div>
  );
};
