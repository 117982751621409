import { useMemo, useState, useEffect } from 'react';
import { useLocation, useMatches } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';

import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { RouteCategory, RouteLinks, RouterUtils } from '~anyx/shared/utils';

import { WorkspaceModeSwitcher } from '../workspace-mode-switcher';

import { NavigationLink } from './NavigationLink';
import { navItemStyles } from './styles';

export interface SidenavProps {
  links: Array<RouteLinks>;
  onLinkClick?: () => void;
}

const classes = navItemStyles();

const removeDynamicSegment = (path = '') => {
  const segments = path.split('/').filter(Boolean);
  if (segments.length > 0 && segments?.[0]?.startsWith(':')) {
    segments.shift();
  }
  return '/' + segments.join('/');
};

// sort by number of slash inside the path, the more come first because it is more specified
const sortBySlash = (a: string, b: string) => {
  const countSlash = (path: string) => path.match(/\//g || [])?.length || 0;
  return countSlash(b) - countSlash(a);
};

const getPathPrefixes = (category: RouteCategory): string[] => {
  const prefixes = new Set<string>();

  const addPathPrefix = (path: string | undefined) => {
    if (path) {
      const cleanedPath = removeDynamicSegment(path);
      if (cleanedPath) {
        prefixes.add(cleanedPath);
      }
    }
  };

  // Add the prefix from the main category
  addPathPrefix(category.activePath);

  // Process child categories
  category.children?.forEach((child) => {
    addPathPrefix(child.activePath);

    // If the child is a menu type, process its children as well
    if (child.type === 'menu' && child.children) {
      child.children.forEach((subChild) => {
        addPathPrefix(subChild.activePath);
      });
    }
  });

  return Array.from(prefixes);
};

export const NavigationLinks = ({ links, onLinkClick = () => null }: SidenavProps) => {
  const location = useLocation();
  const matches = useMatches();
  const [activeMenu, setActiveMenu] = useState<string>();
  const active = useMemo(() => {
    let activePath = '';
    const matchedPaths = matches.map((match) => {
      // Remove workspaced path to apply normal logic
      return RouterUtils.replaceParamsWithId(match.pathname, match.params).replace(
        '/:workspaceMasterStoreDataId',
        ''
      );
    });

    const activeLink = links.find(({ category }) => {
      const prefixes = getPathPrefixes(category).sort(sortBySlash);

      return prefixes.some((prefix) => {
        if (matchedPaths.includes(prefix)) {
          activePath = prefix;
          return true;
        }
        return false;
      });
    });

    return {
      category: activeLink?.category.name.key,
      path: activePath,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, matches, links]);

  useEffect(() => {
    setActiveMenu(active.category);
  }, [active]);

  return (
    <>
      <nav className="px-2">
        <Accordion.Root type="single" value={activeMenu} onValueChange={setActiveMenu}>
          {links.map(({ category }, i) => {
            return (
              <div key={i} className="last-child:mb-0 mb-1">
                <NavigationLink
                  category={category}
                  activePath={active.path}
                  isActive={active.category === category.name.key}
                  setActiveMenu={setActiveMenu}
                  onLinkClick={onLinkClick}
                />
              </div>
            );
          })}
        </Accordion.Root>
      </nav>
      <hr className="my-4" />
      <Restricted
        // Other module do not support all store view.
        permissions={[
          [Auth0Permissions.CAN_USE_ANALYTICS],
          [Auth0Permissions.CAN_USE_DATASOURCE],
          [Auth0Permissions.CAN_USE_ORDER],
          [Auth0Permissions.CAN_USE_PRODUCT],
          [Auth0Permissions.CAN_USE_SHIPPING],
        ]}
      >
        <WorkspaceModeSwitcher className={classes.root({ class: 'justify-between px-4' })} />
      </Restricted>
    </>
  );
};
