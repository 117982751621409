import { useCallback, useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { useSelfCtx } from '~anyx/common/self';
import { MdAnyXModuleEnum, MdUserRole } from '~anyx/shared/graphql';

import { Permission, Restriction } from '../../models';

import { Auth0Permissions } from './permission.model';
import { PermissionContext } from './PermissionContext';

export const PermissionProvider = ({
  children,
  permissionPath,
}: {
  children: React.ReactNode | React.ReactNode[];
  permissionPath: string;
}) => {
  const {
    self: { role, canManageUsers, accessAllStores, availableModules },
  } = useSelfCtx();
  const { user } = useAuth0();

  const allPermissions = useMemo(() => {
    const hasModule = (anyxModule: MdAnyXModuleEnum) => {
      return availableModules.includes(anyxModule);
    };

    return [
      // AUTH0 Permissions
      ...(user?.[permissionPath] || []),
      // Those fields permission are currently set in BE rather then auth0, in order to
      // mantain a correct flow in FE, we manually add them here.
      ...(canManageUsers ? [Auth0Permissions.CAN_MANAGE_USERS] : []),
      ...(accessAllStores ? [Auth0Permissions.CAN_SEE_CURRENT_AND_FUTURE_STORES] : []),
      ...(hasModule(MdAnyXModuleEnum.ORDERS) ? [Auth0Permissions.CAN_USE_ORDER] : []),
      ...(hasModule(MdAnyXModuleEnum.PRODUCTS) ? [Auth0Permissions.CAN_USE_PRODUCT] : []),
      ...(hasModule(MdAnyXModuleEnum.WORKFLOW) ? [Auth0Permissions.CAN_USE_WORKFLOW] : []),
      ...(hasModule(MdAnyXModuleEnum.CRM) ? [Auth0Permissions.CAN_USE_CRM] : []),
      ...(hasModule(MdAnyXModuleEnum.ANALYTICS) ? [Auth0Permissions.CAN_USE_ANALYTICS] : []),
      ...(hasModule(MdAnyXModuleEnum.INVENTORIES) ? [Auth0Permissions.CAN_USE_INVENTORY] : []),
      ...(hasModule(MdAnyXModuleEnum.DATASOURCES) ? [Auth0Permissions.CAN_USE_DATASOURCE] : []),
      ...(hasModule(MdAnyXModuleEnum.SHIPPING_LABELS) ? [Auth0Permissions.CAN_USE_SHIPPING] : []),
    ];
  }, [accessAllStores, availableModules, canManageUsers, permissionPath, user]);

  const hasAuth0Permission = useCallback(
    (permission: Permission) => {
      return allPermissions.includes(permission) || false;
    },
    [allPermissions]
  );

  const isAllowedTo = useCallback(
    (permissions: Permission[][]) => {
      const checks = permissions.map((permissionSet) => {
        const permissionSetChecks = permissionSet.map((permission) =>
          hasAuth0Permission(permission)
        );
        return permissionSetChecks.every((setCheck) => setCheck);
      });
      return checks.some((check) => check);
    },
    [hasAuth0Permission]
  );

  const isRestrictedTo = useCallback(
    (restrictions: Restriction[][]) => {
      const checks = restrictions.map((restrictionSet) => {
        const restrictionSetChecks = restrictionSet.map((restriction) =>
          hasAuth0Permission(restriction)
        );
        return restrictionSetChecks.every((setCheck) => setCheck);
      });
      return checks.some((check) => check);
    },
    [hasAuth0Permission]
  );

  const isUserRole = useCallback(
    (roles: MdUserRole[]) => {
      return roles.includes(role);
    },
    [role]
  );

  return (
    <PermissionContext.Provider value={{ isAllowedTo, isUserRole, isRestrictedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};
