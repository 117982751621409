import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NavLink } from '@any-ui-react/core';
import * as Accordion from '@radix-ui/react-accordion';

import { RouteCategory, useScreenBreakpoint } from '~anyx/shared/utils';

import { MenuLink } from './MenuLink';
import { navItemStyles } from './styles';

export const AccordionLink = ({
  activePath,
  isActive,
  category,
  onLinkClick,
  setActiveMenu,
}: {
  activePath: string;
  isActive: boolean;
  category: RouteCategory;
  onLinkClick: () => void;
  setActiveMenu: (value?: string) => void;
}) => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenBreakpoint('lg');
  const classes = navItemStyles({ active: isActive });
  const navigate = useNavigate();

  return (
    <Accordion.Item value={category.name.key}>
      <Accordion.Header>
        <Accordion.Trigger asChild={true}>
          {isLargeScreen ? (
            <NavLink
              active={category.activePath === activePath}
              classNames={{
                root: classes.root({
                  active: category.activePath === activePath,
                }),
                section: classes.icon({
                  active: category.activePath === activePath || isActive,
                }),
                label: classes.title({
                  active: category.activePath === activePath,
                }),
              }}
              label={t(category.name.key, category.name.options || {})}
              leftSection={category.icon?.({ className: classes.icon() })}
              onClick={() => {
                // TODO: Clean up this once finished demo.
                if (category.disabled) return;
                onLinkClick();
                navigate(category.path ?? '');
              }}
              rightSection={category.aside}
            />
          ) : (
            <NavLink
              onClick={() => {
                // TODO: Clean up this once finished demo.
                if (category.disabled) return;
                setActiveMenu(category.name.key);
              }}
              active={category.activePath === activePath || isActive}
              classNames={{
                root: classes.root({
                  active: category.activePath === activePath,
                }),
                section: classes.icon({
                  active: category.activePath === activePath || isActive,
                }),
                label: classes.title({
                  active: category.activePath === activePath,
                }),
              }}
              leftSection={category.icon?.({
                className: classes.icon(),
              })}
              label={t(category.name.key, category.name.options || {})}
              rightSection={category.aside}
            />
          )}
        </Accordion.Trigger>
      </Accordion.Header>
      <div className="flex flex-col">
        {!isLargeScreen && (
          <Accordion.Content key={category.name.key}>
            <NavLink
              active={category.activePath === activePath}
              classNames={{
                root: classes.root({ active: false, light: true }),
                label: classes.title({ class: 'ml-8' }),
              }}
              label={t(category.name.key, category.name.options || {})}
              onClick={() => {
                // TODO: Clean up this once finished demo.
                if (category.disabled) return;
                onLinkClick();
                navigate(category.path ?? '');
              }}
              rightSection={category.aside}
            />
          </Accordion.Content>
        )}
        {category.children?.map((link, i) => {
          const isLinkActive = link.activePath === activePath;

          if (link.type === 'menu') {
            return (
              <Accordion.Content key={`${link.name.key}-${i}`}>
                <MenuLink
                  name={t(link.name.key, link.name.options || {})}
                  aside={link.aside}
                  links={link.children}
                  activePath={activePath}
                  menuActive={isLinkActive}
                  path={link.path}
                  onClick={onLinkClick}
                />
              </Accordion.Content>
            );
          }

          return (
            <Accordion.Content key={`${link.name.key}-${i}`}>
              <NavLink
                classNames={{
                  root: classes.root({ active: isLinkActive, light: true }),
                  label: classes.title({ class: 'ml-8' }),
                }}
                active={isLinkActive}
                onClick={() => {
                  // TODO: Clean up this once finished demo.
                  if (category.disabled) return;
                  onLinkClick();
                  navigate(link.path ?? '');
                }}
                label={t(link.name.key, link.name.options || {})}
                rightSection={link.aside}
              />
            </Accordion.Content>
          );
        })}
      </div>
    </Accordion.Item>
  );
};
