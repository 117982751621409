import { SVGProps } from 'react';

import { AnyUIProviderProps, Loader, LoaderProps, MantineTheme } from '@any-ui-react/core';

import { LogoAnychatIcon } from '~anyx/shared/icons';

import { ThemeOtherProps } from './types';

export const ANYCHAT_THEME: Omit<AnyUIProviderProps, 'children'> = {
  primaryColor: 'blue',
  theme: {
    components: {
      NavLink: {
        defaultProps: (theme: MantineTheme) => ({
          color: theme.other?.['secondaryColor'],
          styles: {
            icon: {
              color: theme.colors.cyan[6],
            },
          },
        }),
      },
      Button: {
        defaultProps: (theme: MantineTheme) => ({
          color: theme.other?.['secondaryColor'],
        }),
      },
      Tabs: {
        defaultProps: (theme: MantineTheme) => ({
          color: theme.other?.['secondaryColor'],
        }),
      },
      TabsTab: {
        defaultProps: (theme: MantineTheme) => ({
          color: theme.other?.['secondaryColor'],
        }),
      },
      Loader: {
        defaultProps: (theme: MantineTheme) => ({
          color: theme.other?.['secondaryColor'],
        }),
      },
      SegmentedButtonGroup: {
        defaultProps: (theme: MantineTheme) => ({
          color: theme.other?.['secondaryColor'],
        }),
      },
    },
  },
  other: {
    secondaryColor: 'cyan',
    logo: (props: SVGProps<SVGSVGElement>) => <LogoAnychatIcon {...props} />,
    spinner: (props: LoaderProps) => <Loader {...props} />,
  } satisfies ThemeOtherProps,
};
